import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
// import Seo from "../components/seo";
// import { Helmet } from "react-helmet";
import { Link }from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll'




const ArchivePages = (props) => {

    const [toggle, setToggle] = useState(false);
    const data = props.data;
    const currentLocation = props.location.pathname;

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: "active" } : {}
    }
    
    const pageTitle = data.allStrapiArchive.edges.map(text => {
        let uri = `/archives/${text.node.pathURL}` 
        if(uri === currentLocation){
            return text.node.Title
        }
        return null;
        
    })

    const firstText = data.allStrapiArchive.edges.map(text => {
        let uri = `/archives/${text.node.pathURL}` 
        if(uri === currentLocation){
            return text.node.MacroTrends.MacroTrendTitleText
        }
        return null;
       
    })
    const description = data.allStrapiArchive.edges.map(text => {
        let uri = `/archives/${text.node.pathURL}` 
        if(uri === currentLocation){
            return text.node.MacroTrends.MacroTrendLongTextDescription
        }
        return null;
       
    })

    const HeroDescription = data.allStrapiArchive.edges.map(text => {
        let uri = `/archives/${text.node.pathURL}` 
        if(uri === currentLocation){
            return text.node.HeroContent.HeroDescriptionText
        }
        return null;
    })

    const handleArticleList = data.allStrapiArchive.edges.map(list => {
        let uri = `/archives/${list.node.pathURL}`
        if(uri === currentLocation){ 
            return(
                list.node.MacroTrends.MacroTrendList.map(list_under => (
            
                    <li key={list_under.id}>
                        <div className="list-text-group">
                            <h2>{list_under.ListTextTitle}</h2>
                            <div dangerouslySetInnerHTML={{ __html: list_under.ListDescription }}/>
                        </div>
                        { list_under && list_under.LinkArticle && (
                            <AnchorLink href={`#${list_under.LinkArticle.replace(/\s+/g, '')}`}>
                                <img
                                src="https://cdn.mullenloweus.agency/ninthbrain/images/white-arrow-down.svg"
                                alt="white arrow down button"
                                />
                            </AnchorLink>
                        )}                                              
                    </li>
                ))
            );
         } else {
            return null;
         }
     
    });

    const handleArticleContent = data.allStrapiArchive.edges.map(articles => {
            let uri = `/archives/${articles.node.pathURL}`
            if(uri === currentLocation){ 
                return(
                    articles.node.Articles.map(list => (
                        <div key={list.id} className="article-content">
                            <h2 id={list.Title.replace(/\s+/g, '')}>{list.Title}</h2>
                            <div className="leftImageRightText articleContentWidth">
                                <div  className="left-image">
                                    { list.HalfSizeImage && list.HalfSizeImage.HalfImage && list.HalfSizeImage.HalfImage.url && (
                                        <img src={list.HalfSizeImage.HalfImage.url} alt="pic"/>
                                    )}  
                                </div>
                                <div className="right-text">
                                    <div dangerouslySetInnerHTML={{ __html: list.RightSideTextContent.RightText.replace(/href/g, "target='_blank' href") }}/>
                                </div>
                            </div>
                                <div className="two-column articleContentWidth"   dangerouslySetInnerHTML={{ __html: list.TwoColContent.ColumnText.replace(/href/g, "target='_blank' href") }}/>
                                <div className="full-width-image articleContentWidth">
                                    { list.FullSizeImage && list.FullSizeImage.ImageContent && list.FullSizeImage.ImageContent.url && (
                                            <img src={list.FullSizeImage.ImageContent.url } alt="pic"/>
                                    )}  
                                </div>
                        </div>
                        
                    ))
                );
            } else {
             return null;
            }

    });

    function handleDropdown(e){  
        e.preventDefault();
        setToggle(!toggle)
     }

  return (
    <Layout>
  
                <div className="archive-container">
                    <section id="MenuSection">
                    <menu id="menu">
                            <Link to="/" className="logo"> <img src="https://cdn.mullenloweus.agency/ninthbrain/images/octologo.png" alt="logo"  /></Link>
                            <div className="link-text">
                                    <AnchorLink href='#trends' className="menu-text">Macro Trends</AnchorLink>
                                    <AnchorLink href='#articles' className="menu-text">Articles</AnchorLink>
                                    <a href='/' onClick={handleDropdown} className="menu-text"><span className="dropdownMenu">Archive</span></a>
                                        
                                        {toggle && (
                                            
                                            <div className="dropwdownlist">
                                                <ul>
                                                {data.allStrapiHomepage.edges.map(list => (
                                                    
                                                    list.node.menuList.map(link => (
                                                        <li key={link.slug}>
                                                            <Link getProps={isActive} to={`/archives/${link.slug}`}>{link.menuText}</Link>
                                                        </li>
                                                        ))
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                            
                            </div>
                        </menu>
                    </section>
                    <section id="hero">
                        <div className="content--width">
                            <span className="small-hero-text">Ninthbrain - Archive</span>
                            <h1 className="big-text-group">{pageTitle}</h1>
                            <p className="hero-description-text">{HeroDescription}</p>
                            <div className="hero-scroll-down-btn">
                                <span>Scroll</span>
                                    <img src="https://cdn.mullenloweus.agency/ninthbrain/images/scroll-icon.svg" alt="scroll down button"  />
                                <span>Down</span>
                            </div>
                        
                        </div>
                    </section>

               
                    <section id="trends">
                        <div className="trend-text-group">
                            <div className="left-side">
                                <h2>{firstText}</h2>
                            </div>
                            <div className="right-side">
                                <div className="description" dangerouslySetInnerHTML={{ __html: description }}/>
                                <ul className="list-group-items">
                                  {handleArticleList}
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="articles">
                        <div className="article-group">
                            <div className="article-text-group">
                                <h3>Articles</h3>
                            </div>
                            <div className="article-content-group">
                                {handleArticleContent}
                            </div>
                        
                        
                        </div>
                    </section>
                </div>
          
    </Layout>
  );
};

export default ArchivePages;

export const pageQuery = graphql `
  query ArchiveQuery {
    allStrapiArchive(sort: {fields: Articles___Position}){
        edges {
            node {
                pathURL
                Title
                id
                MacroTrends {
                    MacroTrendTitleText
                    MacroTrendLongTextDescription
                    MacroTrendList {
                      ListTextTitle
                      LinkArticle
                      ListDescription
                      id
                      JumplinkArticle{
                        id
                        title
                      }
                    }
                }
          
                HeroContent {
                    FirstHeroTextTitle
                    SecondHeroTextTitle
                    HeroDescriptionText
                    HeroBackgroundImage {
                      url
                      id
                    }
                    LargeNinthBrainLogo{
                      url
                    }
                }

                Articles {
                    FullSizeImage {
                      ImageContent {
                        url
                      }
                    }
                    HalfSizeImage {
                      HalfImage {
                        url
                      }
                    }
                    Position
                    RightSideTextContent {
                      RightText
                    }
                    TwoColContent {
                      ColumnText
                    }
                    id
                    Title
                  }
                
            }
        }
    }

    allStrapiHomepage {
        edges {
          node {
            menuList {
              menuText
              slug
            }
          }
        }
    }
  }
`
